<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change start date</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <argon-date v-model="form.date" label="New start date" />
            </div>
            <div v-if="orderType === 'monthly'" class="col-12">
              <argon-select
                id="form-off-days"
                label="Off days"
                :options="{
                  choices: offDaysChoices,
                  searchEnabled: false,
                  shouldSort: false,
                }"
                :multiple="true"
                :model-value="form.off_days"
                @update:model-value="form.off_days = $event"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-12">
              <submit-form-button
                :form-submitted="formSubmitted"
                @click="handleClick"
              />
              <argon-button
                color="secondary"
                size="sm"
                class="ms-3"
                data-bs-dismiss="modal"
                @click="$emit('close')"
              >
                Close
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonDate from "@/components/ArgonDate.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiOrders from "@/services/apiOrders";
import apiMiscList from "@/services/apiMiscList";
import { handleError, handleResponse } from "@/lib/helpers";
export default {
  name: "FreezeRescheduleDeliveryModal",
  components: {
    ArgonSelect,
    SubmitFormButton,
    ArgonButton,
    ArgonDate,
  },
  props: {
    modalId: {
      type: String,
      default: "change-start-date-modal",
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderType: {
      type: String,
      required: true,
    },
    offDays: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close", "saved"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        date: "",
        off_days: [],
      },
      offDaysChoices: [],
      formSubmitted: false,
    };
  },
  async mounted() {
    this.form.off_days = this.offDays;
    this.offDaysChoices = apiMiscList.getOffDaysChoices();
  },
  methods: {
    async handleClick() {
      await this.v$.$validate();
      if (this.v$.$validationGroups.form.$invalid) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = {
        start_date: this.form.date,
        order_source: "beetle",
      };
      if (this.orderType === "monthly") {
        data.off_days = this.form.off_days;
      }
      const response = await apiOrders
        .setNewStartDate(this.clientId, this.orderId, data)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("saved");
        this.$emit("close");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        date: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      $validationGroups: {
        form: ["form"],
      },
    };
  },
};
</script>
