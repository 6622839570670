import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiOrders {
  async index(options) {
    let defaultOptions = {
      start: 0,
      length: 10,
    };
    let baseUrl = `/beetle/v1/orders`;
    const params = { ...defaultOptions, ...options };
    const queryParams = buildQueryParams(params);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async publish(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/publish`
    );
  }
  async replaceVariation(data, clientId, orderId) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/replace-variations`,
      data
    );
  }
  async applyPromoCode(clientId, orderId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/apply-promo-code`,
      data
    );
  }
  async changeUser(clientId, invoiceId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/invoices/${invoiceId}/change-user`,
      data
    );
  }
  async removePromoCode(clientId, orderId, promoCodeId) {
    return await httpApi.delete(
      `beetle/v1/clients/${clientId}/orders/${orderId}/delete-promo-code/${promoCodeId}`
    );
  }
  searchOptions(searchValue, searchFields, length = 100) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "client_id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: length,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return column;
      });
    }
    return options;
  }
  foodTypesSearchOptions(searchValue, searchFields, length = 100) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: length,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (options.columns.length > 0) {
      options.order[0]["column"] = 1;
    }
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return {
          ...column,
          search: { value: `^${searchValue}$`, regex: true },
        };
      });
      options.search.value = "";
    }
    return options;
  }
  async foodTypes(clientId, orderId, options) {
    let baseUrl = `beetle/v1/clients/${clientId}/orders/${orderId}/get-food-types`;
    const queryParams = buildQueryParams(options);
    const url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
  async setNewStartDate(clientId, orderId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/set-new-start-date`,
      data
    );
  }
}

export default new apiOrders();
