<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name">Client Id</label>
          <argon-select
            id="client-id"
            label="Client"
            :model-value="filter.client_id"
            :search-options-function="apiClient.searchOptions"
            :search-function="apiClient.dropdown"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: {
                prefix: '',
                separator: ' (',
                suffix: ')',
                fields: ['name', 'email'],
              },
            }"
            @update:model-value="filter.client_id = $event"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="bag-delivery-status"
            >Bag Delivery Status</label
          >
          <select
            id="bag-delivery-status"
            v-model="filter.bag_delivery_status"
            class="form-control"
            multiple
          />
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="status">Status</label>
          <select
            id="status"
            v-model="filter.status"
            class="form-control"
            multiple
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Created between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-after"
            >Created after</label
          >
          <flat-pickr
            id="created-after"
            v-model="filter.start_date"
            class="form-control"
            placeholder="Created after"
            :config="configs.start_date"
            @on-change="onStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-before"
            >Created before</label
          >
          <flat-pickr
            id="created-before"
            v-model="filter.end_date"
            class="form-control"
            placeholder="Created before"
            :config="configs.end_date"
            @on-change="onEndDateChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive overflow-hidden">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client Id
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Customer
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Area
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Area Timing
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Delivery Schedule
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Delivery Status
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import API from "@/services/api";
import ApiMiscList from "@/services/apiMiscList";
import apiClient from "@/services/apiClient";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { dateFormat, handleError } from "@/lib/helpers";
import ArgonSelect from "@/components/ArgonSelect.vue";
export default {
  name: "ListTable",
  components: {
    flatPickr,
    ArgonSelect,
  },
  data() {
    return {
      bagDeliveryStatusChoices: [],
      filter: {
        client_id: "",
        bag_delivery_status: [],
        status: [],
        start_date: dateFormat(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          "YYYY-MM-DD"
        ),
        end_date: dateFormat(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          "YYYY-MM-DD"
        ),
      },
      configs: {
        start_date: {
          maxDate: null,
        },
        end_date: {
          minDate: null,
        },
      },
    };
  },
  computed: {
    apiClient() {
      return apiClient;
    },
  },
  async mounted() {
    await this.initBagDeliveryStatusChoices();
    await this.initStatusChoices();
    this.initDataTable();
    this.initDropdownHandle();
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    dateFormat,
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        searching: false,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "client_id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },

          {
            data: "delivery_date",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? dateFormat(data)
                : "" + "</h6></div>";
            },
          },
          {
            data: "client",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "area",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "area_timing",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "delivery_schedule",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: null,
            render: function (data) {
              let html =
                '<div class="d-flex flex-column justify-content-center">' +
                '<div class="dropdown">' +
                '<button id="btn-for-' +
                data.id +
                '" class="btn btn-sm btn-primary dropdown-toggle mb-0 w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
                data.bag_delivery_status +
                "</button>" +
                '<ul class="dropdown-menu">';
              appInstance.bagDeliveryStatusChoices.forEach((item) => {
                html +=
                  '<li><a class="dropdown-item update-status" data-id="' +
                  data.id +
                  '" data-client_id="' +
                  data.order_id +
                  '" data-order_id="' +
                  data.client_id +
                  '" data-status="' +
                  item.id +
                  '"href="#">' +
                  item.label +
                  "</a></li>";
              });
              html += "</ul>" + "</div>" + "</div>";
              return html;
            },
            orderable: false,
            width: "100px",
          },
          {
            data: "delivery_status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "Active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
          },
        ],
        ajax: function (data, callback) {
          data.filter = {};
          if (appInstance.filter.client_id) {
            data.client_id = appInstance.filter.client_id;
          }
          if (appInstance.filter.status) {
            data.filter["delivery_status"] = appInstance.filter.status;
          }
          if (appInstance.filter.bag_delivery_status) {
            data.filter["bag_delivery_status"] =
              appInstance.filter.bag_delivery_status;
          }
          if (appInstance.filter.start_date) {
            data.filter["start_date"] = appInstance.filter.start_date;
          }
          if (appInstance.filter.end_date) {
            data.filter["end_date"] = appInstance.filter.end_date;
          }
          API.getDeliveryList($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    onStartDateChange(selectedDates, dateStr) {
      this.configs.end_date.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.start_date.maxDate = dateStr;
    },
    async initStatusChoices() {
      const id = "status";
      const response = await ApiMiscList.deliveryStatus().catch(handleError);
      if (response.status == 200) {
        await initChoices(id, {
          choices: formatDataToChoicesJs(
            response.data.data,
            "no empty option",
            { id: "value", value: "value", label: "value" }
          ),
          placeholder: true,
          placeholderValue: "Select delivery status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initBagDeliveryStatusChoices() {
      try {
        const response = await ApiMiscList.bagDeliveryStatus();

        if (response.status === 200 && response.data.data.length > 0) {
          this.bagDeliveryStatusChoices = formatDataToChoicesJs(
            response.data.data,
            "no empty option",
            { id: "value", value: "value", label: "value" }
          );

          await initChoices("bag-delivery-status", {
            choices: this.bagDeliveryStatusChoices,
            placeholder: true,
            placeholderValue: "Select bag delivery status",
            duplicateItemsAllowed: false,
          });
        } else {
          throw new Error("No data received from API");
        }
      } catch (error) {
        console.error("Error fetching bag delivery statuses:", error);
        showMessage("Failed to load bag delivery statuses", "error");
      }
    },
    initDropdownHandle() {
      $(document)
        .off("click")
        .on("click", ".update-status", async function () {
          const $this = $(this);
          const clientId = $this.attr("data-client_id");
          const orderId = $this.attr("data-order_id");
          const status = $this.attr("data-status");
          const dayPackId = $this.attr("data-id");
          const $btn = $("#btn-for-" + dayPackId);
          if ($btn.text() == status) {
            return;
          }

          let data = {
            bag_delivery_status: status,
          };

          const response = await API.updateStatus(
            orderId,
            clientId,
            dayPackId,
            data
          ).catch(handleError);
          if (response.status == 200) {
            $btn.text(status);
            showMessage(
              "Success, Delivery status changed successfully",
              "success"
            );
          } else {
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
