import { httpApi } from "./httpApi.js";
import { handleError } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import { buildQueryParams } from "@/lib/queryHelper";

class API {
  async createUser(data) {
    return await httpApi.post("beetle/v1/users", data);
  }

  async login(data) {
    await httpApi.get("/sanctum/csrf-cookie");
    return await httpApi.post("/login", data);
  }

  async logout() {
    return await httpApi.post("/logout");
  }

  async getCurrentProfile() {
    return await httpApi.get("beetle/v1/profile");
  }

  async getPermissions(params) {
    return await httpApi.get("beetle/v1/permissions?" + params);
  }

  async getUser(id) {
    return await httpApi.get("beetle/v1/users/" + id);
  }

  async getUsers(params) {
    return await httpApi.get("beetle/v1/users?" + params);
  }

  async updateUser(id, data) {
    return await httpApi.post("beetle/v1/users/" + id, data);
  }

  async updateUserRole(id, data) {
    return await httpApi.post("beetle/v1/roles/" + id, data);
  }

  async getClientsPlans(params) {
    return await httpApi.get("beetle/v1/lists/plan-details?" + params);
  }

  async getClient(id) {
    return await httpApi.get("beetle/v1/clients/" + id);
  }

  async getClientDeliveries(id) {
    return await httpApi.get("beetle/v1/clients/" + id + "/deliveries");
  }

  async searchInFreshSales(data) {
    return await httpApi.post("/beetle/v1/crm/search", data);
  }

  async createClient(data) {
    return await httpApi.post("/beetle/v1/clients", data);
  }

  async getFreshSaleContactDetails(contactId) {
    return await httpApi.get("/beetle/v1/crm/contact-details?id=" + contactId);
  }

  async getWalletTransactions(params) {
    return await httpApi.get("/beetle/v1/wallet/transaction-list?" + params);
  }

  async getWallets(params) {
    return await httpApi.get("/beetle/v1/wallet/balance?" + params);
  }

  async getClientDelivery(id, deliveryId) {
    return await httpApi.get(
      "beetle/v1/clients/" + id + "/deliveries/" + deliveryId
    );
  }

  async createClientDelivery(id, data) {
    return await httpApi.post("beetle/v1/clients/" + id + "/deliveries", data);
  }

  async updateClientDelivery(id, delivery_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/deliveries/" + delivery_id,
      data
    );
  }

  async getClientPlanDeliveries(id, plan_id, start_date, end_date) {
    let url =
      "beetle/v1/clients/" + id + "/plan-details/" + plan_id + "/deliveries";
    if (start_date && end_date) {
      url +=
        "?include=clientDailyMenus&start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    }
    return await httpApi.get(url);
  }

  async getClientDeliveryMenu(id, delivery_id) {
    return await httpApi.get(
      "beetle/v1/clients/" + id + "/deliveries/" + delivery_id + "/menu"
    );
  }

  async updateClientDeliveryMenu(id, delivery_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/deliveries/" + delivery_id + "/menu",
      data
    );
  }

  async removeClientDayPackMealMenuItem(id, order, dayPack, dayPackMeal) {
    return await httpApi.delete(
      "beetle/v1/clients/" +
        id +
        "/orders/" +
        order +
        "/day-pack/" +
        dayPack +
        "/day-pack-meal/" +
        dayPackMeal +
        "/delete"
    );
  }

  async getClientDislikes(id) {
    return await httpApi.get("beetle/v1/clients/" + id + "/dislikes");
  }

  async getClientDislikeExclusions(id) {
    return await httpApi.get("beetle/v1/clients/" + id + "/dislike-exclusions");
  }

  async getPromoCodeLists(params) {
    return await httpApi.get("beetle/v1/promo-code?" + params);
  }
  async getDeliveryList(params) {
    return await httpApi.get("beetle/v1/order-day-packs?" + params);
  }
  async createPromoCode(data) {
    return await httpApi.post("/beetle/v1/promo-code", data);
  }

  async showPromoCode(id) {
    return await httpApi.get("/beetle/v1/promo-code/" + id);
  }

  async UpdatePromoCode(id, data) {
    return await httpApi.post("/beetle/v1/promo-code/" + id, data);
  }

  getDislikeTypeChoices() {
    return [
      {
        value: "",
        label: "Select",
        id: "",
      },
      {
        value: "allergen",
        label: "Allergen",
        id: "",
      },
      {
        value: "protein_category",
        label: "Protein Category",
        id: "",
      },
      {
        value: "veg",
        label: "Veg",
        id: "",
      },
      {
        value: "carb",
        label: "Carb",
        id: "",
      },
    ];
  }

  async getMealTypes() {
    return await httpApi.get("beetle/v1/misc/lists/meal-types");
  }

  async updateClientDislike(id, dislike_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/dislikes/" + dislike_id,
      data
    );
  }

  async createClientDislike(id, data) {
    return await httpApi.post("beetle/v1/clients/" + id + "/dislikes", data);
  }

  async deleteClientDislike(id, dislike_id) {
    return await httpApi.delete(
      "beetle/v1/clients/" + id + "/dislikes/" + dislike_id
    );
  }

  async updateClientDislikeExclusion(id, dislike_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/dislike-exclusions/" + dislike_id,
      data
    );
  }

  async createClientDislikeExclusion(id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/dislike-exclusions",
      data
    );
  }

  async deleteClientDislikeExclusion(id, dislike_id) {
    return await httpApi.delete(
      "beetle/v1/clients/" + id + "/dislike-exclusions/" + dislike_id
    );
  }

  // async getClientPlanDetails(id) {
  //   return await httpApi.get("beetle/v1/clients/" + id + "/plan-details");
  // }

  async makeOrder(orderType, client_id, data) {
    return await httpApi.post(
      "/beetle/v1/clients/" + client_id + "/orders/" + orderType,
      data
    );
  }

  async makeCustomOrder(client_id, data) {
    return await httpApi.post(
      "/beetle/v1/clients/" + client_id + "/orders/create-custom-order",
      data
    );
  }

  async makeCustomMealTemplate(data) {
    return await httpApi.post(
      "/beetle/v1/meal-templates/custom-meal-template",
      data
    );
  }

  async copyWeekDetails(templateId, data) {
    return await httpApi.post(
      "/beetle/v1/meal-templates/" + templateId + "/copy-week",
      data
    );
  }
  async templateSummery(templateId) {
    return await httpApi.get(
      "/beetle/v1/meal-templates/" + templateId + "/summary"
    );
  }
  async getClientOrderDetails(id) {
    return await httpApi.get("beetle/v1/clients/" + id + "/orders");
  }

  async addMeal(clientId, orderId, packId, data) {
    return await httpApi.post(
      "/beetle/v1/clients/" +
        clientId +
        "/orders/" +
        orderId +
        "/day-pack/" +
        packId +
        "/add",
      data
    );
  }
  async updateMeal(clientId, orderId, packId, mealId, data) {
    return await httpApi.post(
      "/beetle/v1/clients/" +
        clientId +
        "/orders/" +
        orderId +
        "/day-pack/" +
        packId +
        "/day-pack-meal/" +
        mealId +
        "/change",
      data
    );
  }

  async updateClientPlanDetails(id, plan_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/plan-details/" + plan_id,
      data
    );
  }

  async createClientPlanDetails(id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/plan-details",
      data
    );
  }

  getClientPlanTypeChoices() {
    let options = ["Regular", "Complimentary"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ClientPlanType.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }

  async getClientInvoices(id) {
    return await httpApi.get("beetle/v1/clients/" + id + "/invoices");
  }

  async updateClient(id, data) {
    return await httpApi.post("beetle/v1/clients/" + id, data);
  }

  async getAccountManagers() {
    return await httpApi.get("beetle/v1/roles/3/users/");
  }

  async getNutritionists() {
    return await httpApi.get("beetle/v1/roles/5/users/");
  }

  async getIngredients(params) {
    return await httpApi.get("beetle/v1/ingredients?" + params);
  }

  async getIngredientsChoices() {
    return this.getIngredients("start=0&length=-1");
  }

  async getIngredient(id) {
    return await httpApi.get("beetle/v1/ingredients/" + id);
  }

  getIngredientTypeOptions() {
    let options = ["Protein"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/IngredientType.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async updateIngredient(id, data) {
    return await httpApi.post("beetle/v1/ingredients/" + id, data);
  }

  async createIngredient(data) {
    return await httpApi.post("beetle/v1/ingredients", data);
  }

  async deleteIngredient(id) {
    return await httpApi.delete("beetle/v1/ingredients/" + id);
  }

  async getRecipeChoices() {
    return await this.getRecipes("start=0&length=-1");
  }

  async getRecipes(params) {
    return await httpApi.get("beetle/v1/recipes?" + params);
  }

  async getRecipeTypes() {
    return await httpApi.get("beetle/v1/misc/lists/recipe-types");
  }

  async getKitchens() {
    return await httpApi.get("beetle/v1/kitchens?start=0&length=100");
  }

  async getCuisines() {
    return await httpApi.get("beetle/v1/cuisines?start=0&length=100");
  }

  // async getRecipe(id) {
  //   return await httpApi.get("beetle/v1/recipes/" + id);
  // }

  async getProductShow(productId) {
    return await httpApi.get("beetle/v1/products/" + productId);
  }

  async updateRecipe(id, data) {
    return await httpApi.post("beetle/v1/recipes/" + id, data);
  }

  async createRecipe(data) {
    return await httpApi.post("beetle/v1/recipes", data);
  }

  async deleteRecipe(id) {
    return await httpApi.delete("beetle/v1/recipes/" + id);
  }

  async updateRecipeFoodGroups(id, data) {
    return await httpApi.post("beetle/v1/recipes/" + id + "/food-groups", data);
  }

  async updateRecipeIngredients(id, data) {
    return await httpApi.post("beetle/v1/recipes/" + id + "/ingredients", data);
  }

  async getPlans(params) {
    return await httpApi.get("beetle/v1/plans?" + params);
  }

  async getPlan(id) {
    return await httpApi.get("beetle/v1/plans/" + id);
  }

  async getPlanVariants(id) {
    return await httpApi.get("beetle/v1/plans/" + id + "/variants");
  }

  async getPlanVariant(planId, variantId) {
    return await httpApi.get(
      "beetle/v1/plans/" + planId + "/variants/" + variantId
    );
  }

  async getPlanTypes() {
    return await httpApi.get("beetle/v1/misc/lists/plan-types");
  }

  async getListsInvoices(params) {
    return await httpApi.get("beetle/v1/lists/invoices?" + params);
  }

  async getInvoice(id) {
    return await httpApi.get("beetle/v1/invoices/" + id);
  }
  async getInvoicePdf(id, action = "") {
    return await httpApi.get("beetle/v1/invoices/" + id + "?action=" + action, {
      responseType: "blob",
    });
  }

  async updateAddons(id, data) {
    return await httpApi.post("beetle/v1/addons/" + id, data);
  }

  async createAddons(data) {
    return await httpApi.post("beetle/v1/addons", data);
  }

  async getAddonsDetail(id) {
    return await httpApi.get("beetle/v1/addons/" + id);
  }

  async getValueAddedServiceList(params) {
    return await httpApi.get("/beetle/v1/value-added-services?" + params);
  }

  async getDiscountCodetDetail(id) {
    return await httpApi.get("beetle/v1/discount-codes/" + id);
  }

  async createDiscount(data) {
    return await httpApi.post("beetle/v1/discount-codes", data);
  }

  async updateDiscount(id, data) {
    return await httpApi.post("beetle/v1/discount-codes/" + id, data);
  }

  async getAllPlanVariants(params) {
    return await httpApi.get("beetle/v1/all-plan-variants?" + params);
  }

  async getAccountManagersDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/account-manager");
  }

  async getAccountSupervisorDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/account-supervisor");
  }

  async getManagerDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/manager");
  }

  async getSuperAdminDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/super-admin");
  }

  async getAccountAdminDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/account-admin");
  }

  async getSalesAgentDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/sales-agent");
  }
  async getSalesTeamLeaderDashboardData() {
    return await httpApi.get("beetle/v1/dashboard/sales-team-leader");
  }
  async getNutritionistDashboardData() {
    return await httpApi.get("beetle/v1/nutrition-queue");
  }

  async getListsConsultations(client_id) {
    return await httpApi.get(
      "beetle/v1/clients/" + client_id + "/consultations"
    );
  }

  async getConsultationsDetrails(client_id, consultations_id) {
    return await httpApi.get(
      "beetle/v1/clients/" + client_id + "/consultations/" + consultations_id
    );
  }

  async createConsultations(id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/consultations",
      data
    );
  }

  async updateConsultations(id, record_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/consultations/" + record_id,
      data
    );
  }

  getInvoiceTypeChoices(empty_option_name) {
    let options = ["Debit Note", "Credit Note"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getOrderTypes(type = "") {
    let result = [];
    let options = ["Monthly", "Weekly"];
    if (type == "no empty option") {
      options = ["monthly", "weekly", "single"];
    } else {
      result = [
        {
          value: "",
          label: "Select",
          id: "",
        },
      ];
    }

    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getOrderSources() {
    let result = [];
    let options = ["web", "app", "beetle"];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getAllowedPromoCodeCategories() {
    let result = [];
    let options = ["food", "supplements"];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getDepositTypes() {
    let options = ["deposited", "refunded"];
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  getPromoCodeTypes() {
    let options = ["percent", "store_credit", "cart_discount"];
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  getCodeCategoryTypes() {
    let options = [
      "brand_ambassador",
      "business_development",
      "customer_service",
      "management",
      "marketing",
      "personal_trainer",
      "sales",
    ];
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  getPaymentTypes() {
    let options = [
      "customer_cancelled",
      "pending",
      "paid",
      "failed",
      "success",
    ];
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getInvoiceInitiatorTypeChoices(empty_option_name) {
    let options = [
      "AM",
      "New Biz",
      "Gymnation",
      "Partner In Health",
      "Business Development",
      "Brand Ambassador",
      "Organic",
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceInitiatorType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getPurchaseTypeChoices(empty_option_name) {
    let options = ["Retention", "New Sign Up", "Bounce Back"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PurchaseType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getSourceChoices(empty_option_name) {
    let options = ["Web", "Mobile App", "Ladybird"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/Source.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getPaymentSourceChoices(empty_option_name) {
    let options = [
      "Cash",
      "POS Terminal",
      "Cheque",
      "Bank Deposit",
      "Gift Card",
      "Payfort",
      "Postpay",
      "Apple Pay",
      "Wallet",
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PaymentSource.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getManualPaymentSourceChoices(empty_option_name) {
    let options = ["Cash", "Cheque", "POS Terminal", "Bank Deposit"];
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async saveManualPaymentInvoice(invoice_number, data) {
    return await httpApi.post(
      "beetle/v1/invoices/" + invoice_number + "/add-manual-payment",
      data
    );
  }

  getPaymentStatusChoices(empty_option_name) {
    let options = ["Paid", "Cancelled", "Rejected", "Pending"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PaymentStatus.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getInvoiceStatusChoices(empty_option_name) {
    let options = ["Draft", "Published", "Paid", "Completed", "Cancelled"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceStatus.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getPlanVariantPackageTypes() {
    let options = ["Full", "AM", "PM"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PackageType.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getProductTypes(type) {
    const result = {
      custom_meal: [
        {
          type: "protein",
          title: "Protein",
        },
        {
          type: "veg",
          title: "Veg",
        },
        {
          type: "carb",
          title: "Carbs",
        },
        {
          type: "sauce",
          title: "Sauce",
        },
      ],
      breakfast: [
        {
          type: "breakfast",
          title: "Breakfast",
        },
        {
          type: "sauce",
          title: "Sauce",
          sometimes: true,
        },
      ],
      snacks: [
        {
          type: "snacks",
          title: "Snacks",
        },
      ],
      signature: [
        {
          type: "signature",
          title: "Signature",
        },
      ],
    };
    if (type === "order") {
      result.drinks = [
        {
          type: "drinks",
          title: "Drinks",
        },
      ];
    }
    return result;
  }

  getPlanSizes() {
    let options = ["Regular", "Plus", "Wellness", "Athlete"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PlanSize.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getBagStatusLists() {
    let options = ["Active", "Inactive", "Torn", "Lost", "Sent to Repair"];
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async updatePlan(id, data) {
    return await httpApi.post("beetle/v1/plans/" + id, data);
  }

  async updatePlanVariant(planId, planVariantId, data) {
    return await httpApi.post(
      "beetle/v1/plans/" + planId + "/variants/" + planVariantId,
      data
    );
  }

  async createPlan(data) {
    return await httpApi.post("beetle/v1/plans/", data);
  }

  async createPlanVariant(planId, data) {
    return await httpApi.post("beetle/v1/plans/" + planId + "/variants/", data);
  }

  async createFoodGroup(data) {
    return await httpApi.post("beetle/v1/food-groups/", data);
  }

  async updateFoodGroup(id, data) {
    return await httpApi.post("beetle/v1/food-groups/" + id, data);
  }

  async getFoodGroup(id) {
    return await httpApi.get("beetle/v1/food-groups/" + id);
  }

  async changeBagStatus(data) {
    return await httpApi.post(
      "beetle/v1/bag-movements/change-bag-status",
      data
    );
  }

  async getBagMovements(params) {
    return await httpApi.get("beetle/v1/lists/bag-movements?" + params);
  }

  async getBagDepositList(params) {
    return await httpApi.get("beetle/v1/lists/bag-deposits?" + params);
  }

  async getBagMovement(id) {
    return await httpApi.get("beetle/v1/bag-movements/" + id);
  }

  async getClientBagMovements(client_id, params) {
    return await httpApi.get(
      "beetle/v1/clients/" + client_id + "/bag-movements?" + params
    );
  }

  async getDashboardStatistics() {
    return await httpApi.get("beetle/v1/dashboard");
  }

  async getInvoiceMonthToDate(options) {
    let baseUrl = `beetle/v1/graphs/invoice/mtd`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }

  async getRevenueMonthToDate(options) {
    let baseUrl = `beetle/v1/graphs/revenue/mtd`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }

  async getAddons(params) {
    return await httpApi.get("beetle/v1/addons?" + params);
  }

  async getMealType() {
    return await httpApi.get("beetle/v1/misc/lists/meal-types");
  }

  async updateStatus(client_id, order_id, day_pack_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" +
        client_id +
        "/orders/" +
        order_id +
        "/day-pack/" +
        day_pack_id +
        "/update-bag-delivery-status",
      data
    );
  }

  getClientDeliveryScheduleChoices() {
    let options = ["Morning", "Evening"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/DeliverySchedule.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }

  formatDataToChoicesJs(data, empty_option, fields) {
    return formatDataToChoicesJs(data, empty_option, fields);
  }

  formatUserPermissionsToCaslAbilities(data) {
    let result = [];
    for (let i in data) {
      let t = data[i].name.split(".");
      result.push({
        action: t[1],
        subject: t[0],
      });
    }
    return result;
  }

  handleError(err) {
    return handleError(err);
  }
}

export default new API();
